@charset "UTF-8";
:root {
  --color-rojo: #e62a3e;
  --color-rojo-dark: #381015;
  --gris-10: #000;
  --gris-8: #282828;
  --gris-texto: #4f4f4f;
  --trans: 0.14s all ease-in-out; }

/* ==========================================================================
    font-family: 'Lato', sans-serif;
    font-family: 'Montserrat', sans-serif;
    hello
========================================================================== */
body {
  color: var(--gris-texto);
  font-size: 16px;
  line-height: 1.5;
  font-family: "Lato", sans-serif; }

p strong {
  color: var(--gris-10); }

.parallax-window {
  position: relative;
  min-height: 420px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center; }

.page-template-front-page .parallax-window {
  display: block; }

.btn {
  border-radius: 50px !important;
  padding: 0.75rem 1.5rem !important;
  font-size: 14px; }

.btn:active {
  color: #fff; }

/* HEADER PÁGINAS
-------------------------------------------------- */
.header-titulo {
  color: #fff;
  font-size: 40px !important;
  margin: 0px;
  font-weight: 700;
  margin-bottom: 10px; }

.header-seccions {
  background-color: var(--color-rojo-dark);
  padding: 60px 0;
  text-align: center;
  text-align: center; }

.header-single {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  text-align: center;
  width: 70%; }

#breadcrumbs {
  font-size: 13px;
  color: #fff;
  letter-spacing: 0.5px;
  margin-bottom: 0; }

#breadcrumbs a {
  color: #fff;
  font-weight: 500;
  text-decoration: underline; }

@media (max-width: 991px) {
  .header-single {
    width: 100%;
    margin-left: 20px;
    margin-right: 20px; }
  .header-titulo {
    font-size: 20px !important; }
  .parallax-window {
    position: relative;
    min-height: 200px; }
  #breadcrumbs {
    font-size: 11px;
    letter-spacing: 0; } }

/* Menu principal */
.ubermenu-nav {
  text-align: center;
  margin-bottom: 5px !important; }

.ubermenu-nav > li.ubermenu-item > a span.ubermenu-target-text {
  color: #fff !important; }

.ubermenu-nav li.ubermenu-item a.ubermenu-target .fa-angle-down::before {
  color: #fff !important; }

.ubermenu-nav li.ubermenu-item a.ubermenu-target::hover .fa-angle-down::before {
  color: var(--gris-texto) !important; }

.ubermenu-nav > li.ubermenu-item > a:focus,
.ubermenu-nav > li.ubermenu-item > a:hover {
  background-color: #fff !important;
  color: #3c3c3c !important;
  border-radius: 50px;
  transition: var(--trans); }

.ubermenu-nav > li.ubermenu-item > a:focus span.ubermenu-target-text,
.ubermenu-nav > li.ubermenu-item > a:hover span.ubermenu-target-text {
  color: var(--gris-texto) !important; }

.ubermenu-submenu {
  box-shadow: 0px 0px 30px white; }

.top-menu-lang {
  display: flex;
  justify-content: right; }

.wpml-ls-sidebars-wpml-top {
  padding: 0 !important;
  margin-right: 2rem;
  margin-top: 3px; }

.wpml-ls-current-language a {
  font-weight: bold;
  color: #fff !important; }

.overlay-parallax {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); }

.overlay-parallax .container {
  padding: 180px 0; }

ul.navbar-nav > li > a {
  color: #fff !important;
  text-shadow: 0 0px 0 rgba(255, 255, 255, 0) !important;
  font-size: 13px;
  padding: 9px 16px;
  transition: var(--trans); }

ul.navbar-nav > li > a:focus,
ul.navbar-nav > li > a:hover {
  background-color: #fff !important;
  color: #3c3c3c !important;
  border-radius: 50px;
  transition: var(--trans); }

.navbar {
  margin-bottom: 3px !important;
  min-height: 40px; }

.navbar {
  background: none;
  border: none;
  box-shadow: none; }

.navbar-nav {
  display: block;
  float: none;
  text-align: center; }

.navbar-nav > li {
  display: inline-block;
  float: none; }

.dropdown-menu {
  background-color: var(--gris-10);
  color: #cccccc;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.5); }

.dropdown-menu a {
  color: #cccccc !important; }

.dropdown-menu a:hover {
  color: #3c3c3c !important; }

.dropdown-menu h4 {
  color: #fff !important;
  margin-bottom: 20px;
  font-size: 14px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc; }

.dropdown-menu h5 {
  color: #fff !important;
  font-weight: 300 !important;
  font-family: "Lato", sans-serif;
  margin-bottom: 10px;
  font-size: 13px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc; }

/* Clases comunes a todas las páginas */
h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: var(--gris-10);
  font-size: 64px; }

h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #262626; }

@media (max-width: 480px) {
  h1 {
    font-size: 35px; } }

h2.titulo {
  font-size: 40px;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-weight: 700; }

h2.titulo-low {
  font-size: 40px;
  margin-bottom: 30px;
  font-weight: 700; }

h3.titulo {
  font-size: 23px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 35px; }

h3.titulo::after {
  background-color: rgba(128, 128, 128, 0.5);
  content: "";
  display: block;
  height: 2px;
  margin: 20px 0;
  width: 100px; }

h4.titulo {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 35px; }

h4.titulo i {
  display: none; }

h4.titulo::after {
  background-color: rgba(128, 128, 128, 0.5);
  content: "";
  display: block;
  height: 2px;
  margin: 15px 0 15px;
  width: 100px; }

/* Enlaces */
a {
  color: #9d9d9c; }

/* Generales */
.align-right {
  text-align: right; }

.trans {
  background-color: rgba(0, 0, 0, 0.6); }

/*.blanc { color: #fff; }*/
/* Top */
.top {
  background-color: var(--gris-10);
  color: #fff;
  padding: 5px 0; }

.top a {
  color: #bdbdbd;
  font-size: 13px; }

.top a:hover {
  color: vvar(--color-rojo);
  text-decoration: none; }

.top .nav-pills li a:hover {
  background-color: #222;
  color: #fff; }

ul.social-links {
  padding-left: 0px;
  margin-bottom: 0px;
  margin-top: 4px; }

ul.social-links li {
  display: inline-block;
  margin-bottom: 0px; }

ul.social-links li a img {
  width: 33px;
  height: auto; }

/* Header */
header {
  background-color: var(--color-rojo); }

#logo {
  display: table;
  margin: 0 auto;
  width: 150px;
  height: auto;
  padding: 15px 0; }

.mini-rapides h4 {
  text-align: left; }

/* Banner*/
section.banner {
  background: url("../../img/banner.jpg") center center no-repeat;
  background-size: cover;
  padding-top: 250px;
  padding-bottom: 250px; }

/*Seccions */
section.bg-blanc {
  text-align: center;
  padding: 80px 0; }

section.bg-negre {
  text-align: center;
  padding: 50px 0;
  background-color: var(--gris-10); }

section.bg-roig {
  text-align: center;
  padding: 50px 0;
  background-color: var(--color-rojo); }

section.principal {
  padding: 80px 0; }

/* TExtos entradas */
.well-corto {
  padding: 30px 30px 30px 40px;
  background-color: #f4f4f4;
  border-left: 0px solid rgba(128, 128, 128, 0.5); }

/* HOME Descobreix */
h3.roig {
  background-color: var(--color-rojo);
  padding: 20px 10px !important;
  text-align: center;
  color: #fff;
  margin-top: 0px;
  text-transform: uppercase; }

section.descobreix {
  margin-bottom: 1px;
  height: 200px;
  position: relative;
  padding: 0px 0px 500px 0; }

section.descobreix a.overlay {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 30px 0;
  color: #fff; }

section.descobreix a.overlay h2,
section.descobreix a.overlay h3 {
  color: #fff; }

section.descobreix a.overlay {
  display: block;
  transition: background-color 0.2s ease-in-out; }

section.descobreix a.overlay:hover {
  background-color: rgba(0, 0, 0, 0.8); }

section.descobreix a.overlay h2 {
  font-size: 60px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 0px; }

section.descobreix a.overlay h3 {
  margin-bottom: 0px;
  margin-top: 0px;
  text-transform: uppercase;
  font-weight: 400; }

section.descobreix a.overlay h3 {
  transition: right ease 0.5s; }

section.descobreix a.overlay h3:hover {
  right: 10px; }

/* HOME Seccions imatges  */
/*section.patrimoni { background: url('../img/seccions/patrimoni.jpg') no-repeat; background-size: cover; }
*/
/*section.oleoturisme { background: url('../img/seccions/oleoturisme.jpg') no-repeat center center; background-size: cover; }
*/
/*section.tradicions { background: url('../img/seccions/tradicions.jpg') no-repeat center center; background-size: cover;  }
*/
/*section.festes { background: url('../img/seccions/festes.jpg') no-repeat center center; background-size: cover;  }*/
/* FICHA SINGLE PRODUCTO
-------------------------------------------------- */
.titulo-tip {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px; }

.titulo-tip i {
  display: none; }

/* Blog */
article.post {
  margin-bottom: 50px; }

article.post > a {
  display: block; }

article.post > a > .wp-post-image {
  width: 100%;
  margin-bottom: 50px; }

article.post h2.titulo-post {
  font-size: 3rem; }

article.post h2.titulo-post a {
  /* color: #E0211B; */ }

article.post h3.subtitle-post {
  font-weight: 300;
  font-size: 22px;
  margin-bottom: 30px; }

article.post .time a {
  color: #e0211b; }

article.post .entry {
  overflow: hidden; }

article.post .entry strong {
  color: var(--gris-10); }

article.post .entry ul {
  margin-left: 50px; }

article.post .entry h3 {
  margin-left: 50px;
  font-size: 1.3rem;
  font-weight: 400;
  text-transform: uppercase;
  color: #f1ac10; }

article.post .entry h2 {
  margin-bottom: 1.5rem;
  margin-top: 2rem; }

article.post .embed-responsive {
  margin-bottom: 1.5rem;
  margin-top: 2rem; }

article.post .embed-prensa {
  margin-left: 50px; }

article.post blockquote {
  border-left: #b7b7b7 solid 5px;
  padding: 20px 0;
  font-size: 22px; }

article.post .entry .size-full,
article.post .entry .size-large {
  width: 100%;
  height: auto; }

article.post .entry a {
  color: #982727; }

article.post .entry a:hover {
  color: #e0211b;
  text-decoration: none; }

article.post .entry img {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); }

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden; }

.video-container iframe,
.video-container object,
.video-container embed,
.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.acf-map {
  width: 100%;
  height: 400px;
  margin: 0px 0 0px 0;
  border: 1px solid #f6f6f6;
  padding: 7px !important; }

.acf-map-simple {
  width: 100%;
  height: 400px !important;
  margin: 0px 0 20px 0;
  border: 1px solid #f6f6f6;
  padding: 7px !important; }

.acf-map-rutes {
  width: 100%;
  height: 300px !important;
  margin: 0px 0 50px 0;
  border: 1px solid #f6f6f6;
  padding: 7px !important; }

.wikiloc {
  margin-bottom: 50px; }

/* fixes potential theme css conflict */
.acf-map img {
  max-width: inherit !important; }

/* GALERIES D'IMATGES */
.sinpadding [class*="col-"] {
  padding: 1px !important; }

/* HOME Seccio miniatures */
section.mini {
  font-size: 20px;
  background-color: var(--color-rojo);
  padding: 10px 0; }

section.mini a {
  color: #fff;
  text-decoration: none; }

/* Compres */
.ficha-mini {
  background-color: #f7f7f7;
  padding: 20px;
  height: 250px;
  margin-bottom: 30px;
  border-bottom: 3px solid #adadad; }

.ficha-mini h2 {
  font-size: 18px; }

.ficha-mini h2 a {
  color: #222; }

/* HOME Mapa */
section.map-home {
  margin-top: 0px; }

section.map-propiedad {
  margin-bottom: 0px; }

/* FOOTER */
footer {
  border-top: 15px solid var(--color-rojo);
  background-color: var(--gris-8);
  padding: 80px 0px;
  color: #fff; }

footer a {
  color: #fff; }

footer a:hover {
  color: #c6c6c6; }

footer strong {
  color: #fff; }

h3.blanco {
  color: #fff; }

footer .media h2 {
  font-size: 18px;
  margin-top: 0px; }

footer .media h2 a {
  color: #fff; }

footer img.media-object {
  width: 60px;
  height: auto; }

footer .widgettitle {
  color: #fff !important;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase; }

footer .widgettitle::after {
  background-color: rgba(128, 128, 128, 0.5);
  content: "";
  display: block;
  height: 2px;
  margin-top: 10px;
  margin-bottom: 25px;
  width: 100px; }

footer .widget {
  margin-bottom: 50px;
  color: #b1b1b1;
  font-size: 15px; }

footer .widget ul {
  list-style: none;
  padding-left: 0px; }

footer .widget_recent_entries ul li a {
  display: block;
  font-size: 16px;
  color: #fff;
  font-weight: bold; }

footer .widget_recent_entries ul li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dotted #6f6f6f; }

.wikiloc iframe {
  width: 100%;
  height: 450px; }

table.wikiloc-table {
  font-weight: 300 !important; }

@media (max-width: 991px) {
  .wikiloc iframe {
    height: 400px; } }

/* SECCIONES WEB */
.listado-seccion {
  background: #f8f7f7;
  margin-bottom: 40px; }

.listado-seccion .content-resumen {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px; }

.listado-seccion h2 {
  padding-top: 20px;
  font-weight: bold; }

.list-interes li {
  margin-bottom: 10px; }

.list-interes li .label {
  font-size: 13px !important; }

/* FICHA CONTENIDO */
section.principal-ficha {
  padding: 50px 0; }

.foto-ficha {
  padding: 60px 0; }

section.principal-ficha .entry {
  margin-bottom: 50px; }

.img-tecnica {
  padding: 5px;
  background-color: #eeeeee;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.23); }

.galeria-fotos {
  margin-bottom: 1px;
  background-color: #f1f1f1; }

/* FICHA RELACIONADOS */
section.relacionados {
  padding: 50px 0; }

section.relacionados figure h4 {
  font-size: 16px;
  margin-bottom: 3px; }

section.relacionados figure {
  margin-bottom: 30px; }

section.relacionados figure figcaption {
  min-height: 60px; }

section.relacionados figure figcaption h4 a {
  color: var(--gris-8);
  font-weight: bold; }

section.relacionados figure figcaption .glyphicon {
  font-size: 80%; }

section.relacionados figure img {
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.23);
  margin-bottom: 15px; }

.relacionados-inverse {
  background-color: var(--gris-8);
  color: #fff;
  padding: 80px 0; }

.relacionados-inverse figure figcaption {
  padding: 0 0px; }

.relacionados-inverse h4 a {
  color: #d5d5d5 !important; }

.relacionados-inverse h3.titulo {
  color: #fff; }

p.relacionados-mas {
  font-size: 13px !important; }

/* BOTONES */
.btn-gris {
  background-color: var(--gris-8);
  color: #fff;
  margin-bottom: 20px; }

.btn-gris:hover {
  background-image: none;
  background-color: #222;
  color: #fff; }

.btn-roig {
  background-color: var(--color-rojo);
  color: #fff;
  margin-bottom: 20px; }

.btn-roig:hover {
  background-image: none;
  background-color: #d6293c;
  color: #fff; }

.evo_arrows .fa {
  color: #3c3c3c !important; }

.foto-border {
  border: 8px solid #f6f6f6;
  margin-bottom: 10px; }

/* Eventos de mapa */
.map-container iframe {
  width: 100%;
  display: block;
  pointer-events: none;
  position: relative;
  /* IE needs a position other than static */ }

.map-container iframe.clicked {
  pointer-events: auto !important; }

/* Galeria */
.galeria {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.2rem; }

.galeria__item {
  width: 100%;
  height: auto; }

/* Grid relacionados */
.g-relacionados {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem; }

.g-menjar {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem 3rem; }

@media (max-width: 991px) {
  .g-relacionados {
    grid-template-columns: repeat(3, 1fr); } }

@media (max-width: 575px) {
  .g-relacionados {
    grid-template-columns: repeat(2, 1fr); }
  .g-menjar {
    grid-template-columns: repeat(1, 1fr); } }

@media (max-width: 480px) {
  .g-relacionados {
    grid-template-columns: repeat(1, 1fr); } }

/* Agenda */
.agenda__text {
  font-size: 2.3rem;
  margin-bottom: 2.5rem; }

.agenda__boton {
  border: solid 2px var(--color-rojo);
  padding: 12px 20px;
  border-radius: 2.5rem;
  color: var(--gris-texto); }

.agenda__boton:hover {
  background-color: var(--color-rojo);
  text-decoration: none;
  color: #fff; }

.agenda__icon {
  margin-top: 4rem;
  width: 5.5rem;
  height: auto; }

.dp_pec_event_title_sp {
  color: #fff !important; }

.logos-footer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 3rem; }
  .logos-footer__item {
    display: block;
    margin-right: 2rem;
    margin-left: 2rem;
    margin-bottom: 1rem; }
    .logos-footer__item img {
      width: 135px;
      height: auto;
      max-width: 135px;
      max-height: 50px; }

.form-grey {
  padding: 3rem;
  background-color: #f2f2f2;
  border-radius: 6px; }
  @media screen and (max-width: 767px) {
    .form-grey {
      padding: 2rem; } }
  .form-grey__description {
    margin-bottom: 2rem;
    font-size: 2rem; }

/* Bustia */
.page-template-page-bustia iframe {
  width: 100%; }

.wpcf7-acceptance {
  font-size: 12px; }

.drets-deures {
  padding-bottom: 5rem; }

.descarregues {
  padding: 80px 0; }
  .descarregues__list {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 3rem;
    grid-auto-rows: 1fr; }
  .descarregues__item {
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem; }
  .descarregues__imatge img {
    aspect-ratio: 6/4;
    width: 100%;
    height: auto;
    object-fit: cover; }
  .descarregues__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    aspect-ratio: 2;
    width: 100%;
    padding: 30px 15px 15px 15px;
    border: 1px solid rgba(0, 0, 0, 0.1); }
  .descarregues__detalls {
    display: flex;
    align-items: center;
    gap: 1rem; }
  .descarregues__descargar i {
    margin-left: 1rem; }

.archivo {
  padding-bottom: 4rem; }
  .archivo__content h3 {
    margin-bottom: 2.5rem; }
